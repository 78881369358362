import React from 'react';
import styled from 'styled-components';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';

const Wrapper = styled.div`
  display: inline-flex;
  width: 100%;
  max-width: 10em;
`;

const StyledInput = styled(Input)`
  text-align: center;
  flex: 1 1 auto;
  margin: 0 0.2em;
`;

const StyledButton = styled(Button)`
  width: 2em;
  flex: none;
`;

class QuantityInput extends React.Component {
  plusRef = React.createRef();

  handleChange = (event) => {
    const value = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '');

    this.props.onChange(value);
  };

  handleBlur = (event) => {
    // не реагировать если блур был осуществлён посредством клика на кнопку "+"
    // потому что в этом случае сначала handleBlur установить "1" в поле,
    // а потом ещё и метод plus добавить 1. И получится 2. Оно нам не надо.
    if (event.relatedTarget === this.plusRef.current) {
      return;
    }

    const value = +this.props.value;

    if (value <= 1) {
      this.props.onChange('1');
    }
  };

  minus = (event) => {
    event.preventDefault();

    const value = +this.props.value;

    if (parseInt(value) <= 1) {
      return;
    }

    this.props.onChange(parseInt(value) - 1 + '');
  };

  plus = (event) => {
    event.preventDefault();

    const value = +this.props.value;

    this.props.onChange(parseInt(value) + 1 + '');
  };

  render() {
    return (
      <Wrapper className={this.props.className}>
        <StyledButton disabled={this.props.disabled} neutral onClick={this.minus}>
          &ndash;
        </StyledButton>
        <StyledInput
          type="text"
          value={this.props.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={this.props.disabled}
        />
        <StyledButton disabled={this.props.disabled} neutral onClick={this.plus} ref={this.plusRef}>
          +
        </StyledButton>
      </Wrapper>
    );
  }
}

export default QuantityInput;
