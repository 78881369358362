import updateCartMutation from 'graphql/mutations/updateCart.graphql';
import cartQuery from 'graphql/queries/cart.graphql';
import { Mutation } from 'react-apollo';

const UpdateCartMutation = ({ children, ...rest }) => (
  <Mutation
    mutation={updateCartMutation}
    update={(cache, { data: { updateCart } }) => {
      cache.writeQuery({
        query: cartQuery,
        data: { cart: updateCart },
      });
    }}
    {...rest}
  >
    {children}
  </Mutation>
);

export default UpdateCartMutation;
